import {
	Routes,
	Route,
    Outlet,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import Home from './Home';
import NotFound from './NotFound';
import Variant from './Variant';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import Search from './Search';
import OIDCCallback from './OidcCallback';
import Profile from './Profile';
import LoginContainer from './Login';

const AuthenticatedRoute = ({
    children
}) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
        return navigate("/login", {state: {from: location.pathname}});
    }
    return children ? children : <Outlet />;
};

const MyRoutes = () => {
	return (
            <Routes>
                <Route index element={<Home />} />
                <Route element={<AuthenticatedRoute />}>
                    <Route path="/" element={<Search />} />
                    <Route path="/variant/:var_pos" element={<Variant />} />
                    <Route path="/profile" element={<Profile />} />
                    {/* <Route exact={true} path="/logout" element={Logout} /> */}
                </Route>
                <Route exact={true} path="/login" element={< LoginContainer />} />
                <Route exact={true} path="/signin-oidc" element={< OIDCCallback />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
	);
};
export default MyRoutes;
