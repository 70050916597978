import { Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import md5 from '../utils/md5'

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}

function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const useAvatar = () => {
    const auth = useAuth();
    const [avatarUrl, setavatarUrl] = useState();
    const [avatarComponent, setAvatarComponent] = useState();
    const emailHash = md5(auth.user?.profile.email);

    useEffect(() => {
        if (!auth.isAuthenticated) return;
        fetch(`https://en.gravatar.com/profile/${emailHash}.json`)
        .then((response) => {
            if (response.status !== 200)return null;
            return response.json()
        })
        .then((data) => {
            setavatarUrl(data["entry"][0]["thumbnailUrl"])
        });
      }, [auth.isAuthenticated]);

      useEffect(() => {
        if (!auth.isAuthenticated) return;
        const tmp = avatarUrl ? <Avatar src={avatarUrl} /> : <Avatar {...stringAvatar(auth.user?.profile.name)} />;
        setAvatarComponent(tmp);
      }, [auth.isAuthenticated, avatarUrl]);


    return {
            avatarComponent,
            avatarUrl
    };
}

export default useAvatar;