import { configureStore } from "@reduxjs/toolkit"
import restableReducer from './Components/Slices/restableSlice'
import resvarReducer from './Components/Slices/resvarSlice'
import resfullReducer from './Components/Slices/resfullSlice'
import loadingReducer from './Components/Slices/loadingSlice'
import avatarReducer from './Components/Slices/avatarSlice'
import checkedReducer from './Components/Slices/checkedSlice'

export default configureStore({
    reducer: {
        restable: restableReducer,
        resvar: resvarReducer,
        resfull: resfullReducer,
        loading: loadingReducer,
        avatar: avatarReducer,
        checked: checkedReducer,
    }
})