import React from 'react'
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import { Row, Col, Button } from 'react-bootstrap'
import { restableAction } from './Slices/restableSlice'
import { resvarAction } from './Slices/resvarSlice'
import { resfullAction } from './Slices/resfullSlice'
import { checkedAction } from './Slices/checkedSlice'
import { connect } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';


class SearchTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverRow: null,
            scrollToRow: null,
            segmentColor: false,
            table: null,
            colFilter: false,
            checked: this.props.checked.value//['pos', 'ref', 'alt', 'rsID', 'gnomAD_AF', 'gnomAD_NFE_AF', 'global_frequency', 'Gene', 'Consequence']
        }
    }

    handleColumnChange = (event) => {
        if (event.target.checked) { // if we add column
            this.setState({ checked: [...this.state.checked, event.target.name] })
        } else { //if we remove column
            var myArray = [...this.state.checked]
            const index = myArray.indexOf(event.target.name)
            myArray.splice(index, 1)
            this.setState({ checked: myArray })
        }

    }

    handleOpen = () => {
        this.setState({ colFilter: true })
    }

    handleClose = () => {
        this.setState({ colFilter: false })
        //this.props.chcked.value.forEach(x => this.setState({x: true }))
        this.setState({ checked: this.props.checked.value })

    }

    restoreDefaults = () => {
        //[...this.refs.test.classList].includes('Mui-checked')
        //this.props.checkedAction(['pos', 'ref', 'alt', 'rsID', 'gnomAD_AF', 'gnomAD_NFE_AF', 'global_frequency', 'Gene', 'Consequence'])
        this.setState({ checked: ['pos', 'ref', 'alt', 'rsID', 'gnomAD_AF', 'gnomAD_NFE_AF', 'freq_all', 'Gene', 'Consequence'] })
        //this.setState({ colFilter: false })
    }

    updateGlobal = () => {
        //[...this.refs.test.classList].includes('Mui-checked')
        //this.props.checkedAction(Object.keys(this.refs).filter(x => [...this.refs[x].classList].includes('Mui-checked')))
        this.props.checkedAction(this.state.checked)
        this.setState({ colFilter: false })
    }

    render() {
        const { t } = this.props
        if (!this.props.data) {
            return (<div>Loading</div>)
        } else {


            // filtering table columns
            // var allColumns = this.props.data.length > 0 ? [...pre_cols] : null

            var data = this.props.data.length > 0 ? JSON.parse(JSON.stringify(this.props.data)) : [{ a: 'No data' }]

            // if (data[0].global_frequency) { //if we display input searchtable (found variant list)
            //     data.forEach(x => x.global_frequency = x.global_frequency + ' %')
            // }

            function getTextWidth(text, font) {
                // re-use canvas object for better performance
                const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
                const context = canvas.getContext("2d");
                context.font = font;
                const metrics = context.measureText(text);
                return metrics.width;
            }

            function width(data, col) {
                try { //not really needed, but sometimes old results have 'comment' instead of 'commentS' and stuff breaks
                    if (data.length > 1000) {
                        return window.innerWidth / 10
                    } else {
                        var width = Math.max(100, ...data.map(y => getTextWidth(y[col].toString()) * 1.9 + 5), getTextWidth(col.toString()) * 1.9 + 25)
                        return width
                    }
                } catch (e) {
                    return (100)
                }
            }

            // const col_names = {
            //     pos: 'Position', ref: "Reference", alt: 'Alternate', global_frequency: 'Global frequency', region: 'Region', sex: 'Sex frequency',
            //       gt: "Genotype", annot: "Annotation"
            // }
            const vep_cols = 'Allele|Consequence|IMPACT|SYMBOL|Gene|Feature_type|Feature|BIOTYPE|EXON|INTRON|HGVSc|HGVSp|cDNA_position|CDS_position|Protein_position|Amino_acids|Codons|Existing_variation|DISTANCE|STRAND|FLAGS|VARIANT_CLASS|SYMBOL_SOURCE|HGNC_ID|CANONICAL|MANE|TSL|APPRIS|CCDS|ENSP|SWISSPROT|TREMBL|UNIPARC|REFSEQ_MATCH|SOURCE|GIVEN_REF|USED_REF|BAM_EDIT|GENE_PHENO|SIFT|PolyPhen|DOMAINS|miRNA|HGVS_OFFSET|AF|AFR_AF|AMR_AF|EAS_AF|EUR_AF|SAS_AF|AA_AF|EA_AF|gnomAD_AF|gnomAD_AFR_AF|gnomAD_AMR_AF|gnomAD_ASJ_AF|gnomAD_EAS_AF|gnomAD_FIN_AF|gnomAD_NFE_AF|gnomAD_OTH_AF|gnomAD_SAS_AF|MAX_AF|MAX_AF_POPS|CLIN_SIG|SOMATIC|PHENO|PUBMED|MOTIF_NAME|MOTIF_POS|HIGH_INF_POS|MOTIF_SCORE_CHANGE'.split("|")
            const kraje = ['PHA', 'JHC', 'JHM', 'KVK', 'HKK', 'LBK', 'MSK', 'OLK', 'PAK', 'PLK', 'STC', 'ULK', 'VYS', 'ZLK']

            data.forEach(x => x.pos = x.chrom + ':' + x.pos + '>' + x.alt)
            data.forEach(x => x.freq_all = Number(x.freq_all.toPrecision(3)))
            data.forEach(x => x.rsID = x.annot.split('|')[vep_cols.indexOf('Existing_variation')]) //17
            data.forEach(x => x.gnomAD_AF = x.annot.split('|')[vep_cols.indexOf('gnomAD_AF')]) //52, combined 
            data.forEach(x => x.gnomAD_NFE_AF = x.annot.split('|')[vep_cols.indexOf('gnomAD_NFE_AF')]) //58, Non-Finnish European 
            data.forEach(x => x.Gene = x.annot.split('|')[vep_cols.indexOf('SYMBOL')]) //4, Non-Finnish European 
            data.forEach(x => x.Consequence = x.annot.split('|')[vep_cols.indexOf('Consequence')]) //1, Non-Finnish European 
            data.forEach(x => x.population_freq = x.count_samples / x.total_samples) //1, Non-Finnish European 
            //kraje.forEach(kraj => data.forEach(x => x[kraj] = JSON.parse(x.region)[kraj] != undefined ? JSON.parse(x.region)[kraj] : 0))
            kraje.forEach(kraj => data.forEach(x => x[kraj] = Number(x["freq_" + kraj].toPrecision(3))))
            //data.forEach(x => x.alt = Object.keys(JSON.parse(x.alt)))


            const col_names = {
                pos: 'Position', ref: "Reference", alt: 'Alternate', rsID: "rs ID", freq_all: 'ACGT Czech Republic frequency', gnomAD_AF: "gnomAD global frequency", gnomAD_NFE_AF: "gnomAD Non-Finnish European frequency",
                Gene: 'Gene', Consequence: 'Consequence', region: 'Region', count_all: "Total allele number", population_freq: "Population frequency", annot: "Annotation",
                PHA: 'Hlavní město Praha', JHC: 'Jihočeský kraj', JHM: 'Jihomoravský kraj', KVK: 'Karlovarský kraj', HKK: 'Královehradecký kraj', LBK: 'Liberecký kraj', MSK: 'Moravskoslezský kraj',
                OLK: 'Olomoucký kraj', PAK: 'Pardubický kraj', PLK: 'Plzeňský kraj', STC: 'Středočeský kraj', ULK: 'Ústecký kraj', VYS: 'Vysočina', ZLK: "Zlínský kraj"
            }

            //var pre_cols = data.length > 0 ? Object.keys(data[0]) : null
            var pre_cols = data.length > 0 ? Object.keys(col_names) : null
            var columns = this.props.data.length > 0 ?
                pre_cols.map(x => [{
                    Header: col_names[x],
                    accessor: (d) => d[x],
                    id: x,
                    minWidth: width(data, x),
                    maxWidth: 200,
                    sortMethod: (a, b) => {
                        // if (['freq_all'].includes(x)) { //length col filtering below in 'CNV'
                        //     return Number(a.replace(/,/g, '')) > Number(b.replace(/,/g, '')) ? 1 : -1
                        // } else 
                        // if (['chromosome', 'Chromosome'].includes(x)) {
                        //     return 0
                        // } else 
                        if (['pos'].includes(x)) {
                            if (Number(a.split(':')[1].replace(/\D/g, "")) > Number(b.split(':')[1].replace(/\D/g, ""))) { return 1 }
                            else { return -1 }
                        } else if(['gnomAD_AF', 'gnomAD_NFE_AF'].includes(x)){
                            if (Number(a) > Number(b)) { return 1 }
                            if (Number(a) < Number(b)) { return -1 }
                            return 0;
                        } else {
                            a = a === null || a === undefined ? -Infinity : a
                            b = b === null || b === undefined ? -Infinity : b
                            a = typeof a === "string" ? a.toLowerCase() : a
                            b = typeof b === "string" ? b.toLowerCase() : b
                            if (a > b) { return 1 }
                            if (a < b) { return -1 }
                            return 0;
                        }
                    }

                }][0]) :
                [{ Header: "No data", accessor: 'a' }]

            Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0]);
            }

            // if (this.props.res != true) { //if not result table
            //     columns.move(7, 0) // Move global freq to the beginning
            //     columns.move(7, 0) // Move position to the beginning    
            // }


            var numFilter = function (filter, row) {
                if (row[filter.id] && row[filter.id] !== "") {
                    if (filter.value.includes(";") && (filter.value.split(";")[1] !== "" && filter.value.split(";")[1] !== ">" && filter.value.split(";")[1] !== "<")) {
                        var filter1 = filter.value.split(";")[0]
                        var filter2 = filter.value.split(";")[1]
                        var filterLow = filter1.includes("<") ? Number(filter1.split("<")[1]) : Number(filter2.split("<")[1])
                        var filterHigh = filter2.includes(">") ? Number(filter2.split(">")[1]) : Number(filter1.split(">")[1])

                        if (row[filter.id] < filterLow && row[filter.id] > filterHigh) {
                            return (true)
                        } else {
                            return (false)
                        }
                    } else if (filter.value.includes("<")) {
                        if (row[filter.id] < Number(filter.value.split("<")[1])) {
                            return (true)
                        } else {
                            return (false)
                        }
                    } else if (filter.value.includes(">")) {
                        if (row[filter.id] > Number(filter.value.split(">")[1])) {
                            return (true)
                        } else {
                            return (false)
                        }
                    } else {
                        if (row[filter.id].toString().startsWith(filter.value)) {
                            return (true)
                        } else {
                            return (false)
                        }
                    }
                } else {
                    return (true)
                }
            }
            var charFilter = function (filter, row) {
                if (row[filter.id]) {
                    if (filter.value === " ") {
                        return (row[filter.id] === null)
                    } else {
                        return (row[filter.id].toLowerCase().match("^" + filter.value.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1").toLowerCase()) !== null)
                        // return (row[filter.id].toLowerCase().includes(filter.value.toLowerCase()))
                    }
                } else {
                    return (null)
                }
                // Honestly I have no idea why I have to check if row[filter.id] exsists in the line above. Without the check, there's a crash saying that it's empty if you filter column 5 in first SNV table...
                // The column is almost the same as the others and I cannot even put a breakpoint before when debugging. Anyway, works now.
            }
            var arrFilter = function (filter, row) {
                if (filter.value === null || filter.value.length === 0) {
                    return (true)
                } else if (filter.value.length > 0) {
                    return (row[filter.id].map(x => x.toLowerCase().includes(filter.value.toLowerCase())).some((x) => x == true))
                } else {
                    return (null)
                }
            }
            var varFilter = function (filter, row) {
                if (row[filter.id] && filter.value !== null && filter.value !== "") {
                    if (row[filter.id] === null || row[filter.id].constructor === Array) {
                        return (arrFilter(filter, row))
                    } else if (typeof row[filter.id] === "number" || !isNaN(Number(row[filter.id]))) {
                        return (numFilter(filter, row))
                    } else {
                        return (charFilter(filter, row))
                    }
                } else {
                    if (filter.value === '' || filter.value === ' ' || filter.value === null || filter.value[0] === '') {
                        return (true)
                    } else {
                        return (null)
                    }
                }
            }

            //set column specific filters based on data type
            for (var i = 0; i < columns.length; i++) {
                columns[i].filterMethod = (filter, row) => varFilter(filter, row)
            }


            // var columnFiltersDiv =
            //     <div id="colFiltBtn">
            //         <div id="columnFiltersDiv_height" className="columnFiltersDiv_content">
            //             <ol>
            //                 {columns.map((x) => {
            //                     return (
            //                         <li key={x.id}>
            //                             <input type="checkbox" name={x.id} ref={x.id} onChange={this.checkChange} checked={this.props.checked.value.includes(x.id)} />
            //                             <label htmlFor={x.Header} style={{ 'paddingLeft': '5px' }}>{x.Header}</label>
            //                             <br></br>
            //                         </li>
            //                     )
            //                 }, this)}
            //             </ol>
            //         </div>
            //     </div>;

            var columnFiltersDiv =
                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">{t('var_data')}</FormLabel>
                    <FormGroup>
                        {columns.map((x) => {
                            return (
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                ref={x.id}
                                                name={x.id}
                                                //defaultChecked={this.props.checked.value.includes(x.id) ? true : null}
                                                disabled={x.id == 'pos' ? true : false}
                                                onChange={this.handleColumnChange}
                                                checked={this.state.checked.includes(x.id)} />
                                        }
                                        label={x.Header}
                                    />
                                    {x.id == 'annot' ? <FormLabel component="legend">{t('region_map')}</FormLabel> : null}
                                </div>
                            )
                        }, this)}
                        <Row>
                            <Col>
                                <Button style={{ width: '180px' }} onClick={this.restoreDefaults}>{this.props.t('restore_defaults')}</Button>
                            </Col>
                            <Col>
                                <Button style={{ width: '180px' }} onClick={this.updateGlobal}>{this.props.t('save')}</Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </FormControl>

            var col_list = columns.filter(x => this.props.checked.value.includes(x.id))

            const style = {
                position: 'absolute',
                top: '450px',
                left: '50%',
                transform: 'translate(-50%, -30%)',
                //width: 400,
                maxWidth: 800,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }

            return (
                <div>
                    <Row className="tabButtonsRow">
                        <Col xs={5}>
                        </Col>
                        <Col xs={3}>
                        </Col>
                    </Row >
                    <div style={{ textAlign: 'right' }}>
                        <Button variant="info" size="sm" className="fillAvailableBtns" onClick={this.handleOpen} style={{ width: '200px', margin: '20px' }}> {this.props.t('col_flt')}</Button>
                    </div>
                    <Modal
                        open={this.state.colFilter}
                        onClose={this.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ overflow: 'overlay' }}
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {this.props.t('select_cols')}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {columnFiltersDiv}
                            </Typography>
                        </Box>
                    </Modal>

                    <ReactTable
                        //ref={r => { this.updateTableReference(r) }}
                        data={data}
                        columns={col_list}
                        className={"-striped -highlight ReactTable_" + this.props.table}
                        style={{ maxWidth: window.innerWidth - 50 }}
                        filterable
                        showPagination={data.length <= 20 ? false : true}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                        defaultPageSize={data.length <= 20 ? data.length : 20}
                        defaultSorted={[{ id: 'pos', desc: false, }]}
                        getTdProps={(state, rowInfo, column, instance) => {
                            if (typeof rowInfo !== "undefined" & column.id == 'pos') {
                                return {
                                    class: 'rt-td pos',
                                    style: {
                                        fontWeight: 200,
                                        color: '#1173bb',
                                        // background: this.state.hoverRow === rowInfo.index ? '#99DDFF' : 'white',
                                        cursor: 'pointer'
                                    },
                                    onClick: () => {
                                        this.props.changeRoute(rowInfo.row.pos)
                                        this.props.resvarAction(this.props.restable.value[rowInfo.index])
                                        var position = rowInfo.row.pos.split(':')[1].split('>')[0]
                                        this.props.resfullAction(this.props.restable.value.filter(x => x.pos == position))
                                        //this.props.restableAction([])
                                    }
                                    // ,
                                    // onMouseEnter: () => {
                                    //     this.setState({ hoverRow: rowInfo.index })
                                    // },
                                    // onMouseLeave: () => {
                                    //     this.setState({ hoverRow: null })
                                    // }
                                }
                            }
                            return { style: { fontWeight: 200 } } // little children die if this return is gone
                        }}
                    />
                    <br />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        restable: state.restable,
        checked: state.checked
    }
}
const mapDispatchToProps = { restableAction, resvarAction, resfullAction, checkedAction }
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(SearchTable)
