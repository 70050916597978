import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        value: false
    },
    reducers: {
        loadingAction: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { loadingAction } = loadingSlice.actions

export default loadingSlice.reducer