import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const setAvatarUrlAsync = createAsyncThunk(
    "avatar/setAvatarUrlAsync",
    async (emailHash, { getState, dispatch }) => {
        const url = await fetch(`https://en.gravatar.com/profile/${emailHash}.json`)
        .then((response) => {
            if (response.status !== 200)return null;
            return response.json()
        })
        .then((data) => {
            return data["entry"][0]["thumbnailUrl"]
        });
    //   dispatch(avatarSlice.actions.setAvatarUrl(url));
      return url;
    },
);

export const avatarSlice = createSlice({
    name: 'avatar',
    initialState: {
        url: "https://gravatar.com/avatar/1361fd0c56cb0dbb836e3ba9cf837521",
        status: "pending"
    },
    reducers: {
        setAvatarUrl: (state, action) => {
            console.log("Setting", action.payload)
            state.url = action.payload
            return state
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setAvatarUrlAsync.pending, (state) => {
          state.status = "loading";
        });
        builder.addCase(
            setAvatarUrlAsync.fulfilled,
          (state, action) => {
            state.translations = action.payload;
            state.status = "idle";
          },
        );
    },
})

export const { avatarAction } = avatarSlice.actions

export default avatarSlice.reducer;