import './App.css'
import { Layout } from './Components/Layout';
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'react-oidc-context'
import MyRoutes from './Components/Routers';
import { Provider } from 'react-redux';
import store from './store';
import { useTranslation } from 'react-i18next';
import useLocalStorage from './utils/useLocalStorage';
import { useEffect } from 'react';
import config from './Config';

const oidcConfig = {
  authority: config.REACT_APP_OIDC_AUTHORITY,
  client_id: config.REACT_APP_OIDC_CLIENT_ID,
  scope: config.REACT_APP_OIDC_SCOPE,
  response_type: "code",
  // In case of e-infra cz use http://localhost:3000
  redirect_uri: (new URL(config.REACT_APP_OIDC_REDIRECT_URL, window.location.origin)).href,
  metadata: {
    issuer: config.REACT_APP_OIDC_METADATA_issuer,
    jwks_uri: config.REACT_APP_OIDC_METADATA_jwks_uri,
    authorization_endpoint: config.REACT_APP_OIDC_METADATA_authorization_endpoint,
    token_endpoint: config.REACT_APP_OIDC_METADATA_token_endpoint,
    userinfo_endpoint: config.REACT_APP_OIDC_METADATA_userinfo_endpoint,
    end_session_endpoint: config.REACT_APP_OIDC_METADATA_end_session_endpoint,
  },
  automaticSilentRenew: false,
  checkSessionIntervalInSeconds: 3600
};

const App = () => {

  const { i18n } = useTranslation();
  const [lang, setLang] = useLocalStorage("langiage.setting", i18n.language);

  useEffect(() => {
    if (i18n.language !== lang){
      i18n.changeLanguage(lang);
    }
  }, []);


  return (
    <Provider store={store}>
      <AuthProvider {...oidcConfig}>
        <BrowserRouter>
          {/* <Suspense fallback="...loading"> */}
            <Layout>
              <MyRoutes />
            </Layout>
          {/* </Suspense> */}
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  )
}

export default App;
