import { createSlice } from '@reduxjs/toolkit'

export const resfullSlice = createSlice({
    name: 'resfull',
    initialState: {
        value: []
    },
    reducers: {
        resfullAction: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { resfullAction } = resfullSlice.actions

export default resfullSlice.reducer