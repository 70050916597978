import { useAuth } from "react-oidc-context";
import Search from "./Search";
import einfraLogo from "../utils/e-INFRA_logo_RGB_lilek.svg"
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation()

    return (
        !auth.isLoading && auth.isAuthenticated ? <Search /> :
            <>
                <Grid container display="flex" justifyContent={"center"}>
                    <Grid item md={6} display="flex" alignItems={"center"}>
                        <Paper variant="outlined" elevation={2} sx={{
                            width: "100%",
                            padding: 5,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }} >
                            <Typography variant="h4" sx={{ mb: 3, textAlign: "center" }}>{t('welcome')}</Typography>
                            <Typography variant="h5" sx={{ textAlign: "center" }}>{t("auth.info")}</Typography>
                            <Divider sx={{ width: "50%", margin: "2em auto 1em" }}></Divider>
                            <Typography variant="h6" sx={{ textAlign: "center" }}>{t("auth.login-with")}</Typography>
                            <Button variant="outlined" size="large" sx={{ width: "75%" }} onClick={() => navigate("/login")}>
                                <img src={einfraLogo} style={{ width: "150px" }} />
                            </Button>
                            <Typography style={{ color: "red" }}>{auth.error?.message}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </>
    )
}

export default Home;