import React, { useRef, useState } from 'react'
import { TextField, Autocomplete, Grid, InputAdornment } from '@mui/material'
import { Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
// import PassQuery from './PassQuery'
import { useTranslation } from "react-i18next"
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { restableAction } from './Slices/restableSlice'
import { loadingAction } from './Slices/loadingSlice'
import { useNavigate } from 'react-router-dom'

import './CSS.css'
import { useAuth } from 'react-oidc-context'
import config from '../Config'

function SearchBar(props) {
    const auth = useAuth();
    const api = axios.create({ baseURL: config.REACT_APP_BASE_API_URL, headers: { Authorization: `Bearer ${auth.user?.id_token}` } })
    const textRef = useRef()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [sendQuery, setSendQuery] = useState(false)
    // const [event, setEvent] = useState()
    // const [value, setValue] = useState()

    function passQuery(event, value) {
        // test this and actually filter results + styling
        let query
        if (event.key == 'Enter' | event.type == 'click') {
            if (value == undefined) { // If clicked search btn
                query = textRef.current.value
            } else { // If enter
                query = value
            }
        } else { // If selected from dropdown
            query = value
        }

        if (query.trim() == '') {
            return null
        }
        //props.getQuery(query)

        query = query.trim()

        //Check if query range isn't too big
        if (query.includes('-')) {
            var [pos1, pos2] = query.split(':')[1].split('-')
            var threshold = 10000000
            if (Number(pos2) - Number(pos1) <= threshold) {
                dispatch(loadingAction(true))
                navigate('/')
                api.get('/varList', { params: { string: query } }).then(res => {
                    if (res.data == 'error') {
                        dispatch(restableAction(res.data))
                        dispatch(loadingAction(false))
                    } else if (res.data == '[]') {
                        dispatch(restableAction(null))
                        dispatch(loadingAction(false))
                    } else {
                        dispatch(restableAction(JSON.parse(res.data)))
                    }

                }).catch(function (error) {
                    dispatch(restableAction("Server Error: " + error.message))
                })
            } else {
                if (isNaN(Number(pos1)) | isNaN(Number(pos2))) {
                    dispatch(restableAction("Query Error: The query range does not contain only numbers"))
                } else {
                    dispatch(restableAction("Query Error: The query range is greater than " + threshold + " bases. Please, reduce the query range."))
                }
            }

        } else {
            dispatch(loadingAction(true))
            navigate('/')
            api.get('/varList', { params: { string: query } }).then(res => {
                if (res.data == 'error') {
                    dispatch(restableAction(res.data))
                    dispatch(loadingAction(false))
                } else if (res.data == '[]') {
                    dispatch(restableAction(null))
                    dispatch(loadingAction(false))
                } else if (res.data == 'Query error: wrong query format') {
                    dispatch(restableAction('Query error: wrong query format'))
                    dispatch(loadingAction(false))
                } else if (res.data == 'Database error: cannot connect') {
                    dispatch(restableAction('Database error: cannot connect'))
                    dispatch(loadingAction(false))
                } else {
                    dispatch(restableAction(JSON.parse(res.data)))
                }

            }).catch(function (error) {
                dispatch(restableAction("Server Error: " + error.message))
            })
        }



    }
    // function doQuery(event, value) {
    //     setSendQuery(true)
    //     setEvent(event)
    //     setValue(value)
    // }

    // function stopQuery() {
    //     setSendQuery(false)
    // }

    return (
        <Grid container display="flex" justifyContent={"center"}>
            <Grid item md={6} display="flex" alignItems={"center"}>
                <Autocomplete
                    key={props.trigger}
                    style={{ margin: '5px' }}
                    // onChange={passQuery}
                    // onKeyPress={passQuery}
                    freeSolo
                    disablePortal
                    id="SearchQuery"
                    options={[{ label: '', value: '' }]}
                    sx={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params} label={t('varsearch')} inputRef={textRef} InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button size="large" onClick={passQuery} startIcon={<SearchIcon />}>{t('search')}</Button>
                            </InputAdornment>
                        )
                    }} />}
                    // below makes sure that dropdown appears only if there is any text in search bar
                    open={open}
                    onChange={(event) => {
                        if (event.key == 'Enter') {
                            passQuery(event)
                        }
                    }}
                    onInputChange={(_, value) => {
                        if (value.length === 0) {
                            if (open) setOpen(false);
                        } else {
                            if (!open) setOpen(true);
                        }
                    }}
                    onClose={() => setOpen(false)}
                />
                {/* {sendQuery ?
                    <PassQuery event={event} value={value} text={textRef.current.value} stopQuery={stopQuery}/> :
                    null} */}
            </Grid>
        </Grid>
    )
}
export default SearchBar