import { Avatar, Box, Button, ButtonGroup, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Table, TableCell, TableRow, Grid, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import useAvatar from "../utils/useAvatar";
import einfraLogo from "../utils/e-INFRA_logo_RGB_lilek.svg"
import { Logout } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const ProfileNavBar = ({children, user}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return(
        <>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {children}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => {navigate("/profile")}}>
            {t("auth.profile")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => {handleClose();user.signoutRedirect()}}>
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            {user?.profile?.name}
            {t("auth.logout")}
        </MenuItem>
      </Menu>
      </>
    )
}

const Profile = () => {
    const auth = useAuth();
    const { t } = useTranslation();
    const { _, avatarUrl} = useAvatar();

    return(
        <>
        <Typography variant="h4" sx={{mb:3}}>{t('auth.welcome')} {auth.user?.profile.given_name},</Typography>
        <Grid container spacing={2} alignItems={"center"}>
                <Grid item justifyContent="center" display={"flex"}>
                    <Avatar sx={{width:200,height:200}} variant={"circular"} src={avatarUrl+"?s=200"} alt="Profile picture, gravatar of the logged in user" />
                </Grid>
                <Grid item>
                    <Table>
                        <TableRow>
                            <TableCell>
                                {t('profile.name')}      
                            </TableCell>    
                            <TableCell>
                                {auth.user?.profile.name}    
                            </TableCell>    
                        </TableRow>    
                        <TableRow>
                            <TableCell>
                                {t('profile.organization')}      
                            </TableCell>    
                            <TableCell>
                                {auth.user?.organization || "Masaryk University"}    
                            </TableCell>    
                        </TableRow>    
                        <TableRow>
                            <TableCell>
                                {t('profile.email')}   
                            </TableCell>    
                            <TableCell>
                                {auth.user?.profile.email || "Not defined"}    
                            </TableCell>    
                        </TableRow>    
                        <TableRow>
                            <TableCell>
                                {t('profile.logged')}   
                            </TableCell>    
                            <TableCell>
                                <img width="100px" src={einfraLogo} />
                                <Button variant="outlined" color="error" onClick={() => auth.signoutRedirect()}>{t('auth.logout')}</Button>
                            </TableCell>
                        </TableRow>    
                    </Table>
                </Grid>
        </Grid>
        </>
    );
}

export default Profile