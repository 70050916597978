import { useTranslation } from "react-i18next";

const NotFound = () => {

    const { t } = useTranslation();

    return (
        <h1>
            {t("errors.404.name")}
            <br />
            {t("errors.404.description")}
        </h1>
    )
}

export default NotFound;