import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import cesky from './Translations/cz.json'
import English from './Translations/en.json'

const resources = {
  "en": {
    translation: English,
  },
  "cs": {
    translation: cesky,
  },
};

i18next
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: ['en', 'cs'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next