import { LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import useLocalStorage from "../utils/useLocalStorage";

const LoginContainer = () => {
    const auth = useAuth();
    const location = useLocation();
    const r = "actg-"+(Math.random() + 1).toString(36).substring(7);
    const [authNonce, setAuthNonce] = useLocalStorage(r, "none");

    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                const auth_state = {
                    id: r,
                    redirect: (location?.state?.from || "/").toString(),
                    expires: new Date().toLocaleTimeString(),
                }
                setAuthNonce(auth_state);
            auth.signinRedirect({state: {"auth_request_id": r}});
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);
    
    if (auth.activeNavigator) {
        return <LinearProgress />;
    }
    if (!auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }
}
  
  export default LoginContainer;