import React from 'react'
import { Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import Navigation from './Navigation'
import '../index.css'
import { useTranslation } from 'react-i18next'
import { AppBar, CssBaseline, Grid, Paper, Typography } from '@mui/material'
import ResponsiveAppBar from './AppBar'

export const Layout = ({ children }) => {
    const { t } = useTranslation()

    return (
        <>
            {/* <Navigation /> */}
            <CssBaseline />
            <ResponsiveAppBar />
            <Grid container justifyContent="center">
                <Grid item md={8} sm={12}>
                    <Paper sx={{mt: 5, mb: 10}} elevation={0}>
                        {/* <Typography variant='h2'>{t('welcome')}</Typography> */}
                        {children}
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}