import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Dataset } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { ProfileNavBar } from './Profile';
import useAvatar from '../utils/useAvatar';
import { Navigate, useNavigate } from 'react-router-dom';
import LangSwitcher from './Navigation.LanguageSwitcher';
import { Row } from 'react-bootstrap'
import Modal from '@mui/material/Modal'
import EU from '../www/ACGT.png'
import logo from '../www/acgt_logo.png'
import elixirlogo from '../www/elixirlogo.png'

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openAbout, setOpenAbout] = React.useState(false);

  const auth = useAuth();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { avatarComponent, } = useAvatar();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenAbout = () => {
    setOpenAbout(true)
  }

  const handleCloseAbout = () => {
    setOpenAbout(false)
  }

  const about = <div>
    <b>About:</b>{`
This database is a result of collaboration among 3 universities and 2 companies within the Analysis of Czech Genome for Theranostics (A-C-G-T) project:
• Central European Institue of Technology, Masaryk University Brno (coordinator)
• First and Second Faculty of Medicine, Charles University in Prague
• Institute of Molecular and Translational Medicine - Palacky University Olomouc
• Genomac Research Institute
• EUC Laboratories CGB (from 11/2019)
• Institute of Applied Biotechnologies (only until 10/2019)
The project aimed to sequence whole genomes of 1,000-1,500 healthy Czechs and create an aggregation reference database of genomic variants in the general population.


`}
    <b>Criteria based on which the participants could enroll in the study: </b>
    <br />
    <b>Inclusion criteria:</b>{`
• From 30 to 55 years old.
• Without a serious genetic disease.
• The participant's parents come from the same region of the Czech Republic (the father was born in the same region as the mother). The mother and the father can be from two different regions if their birthplaces are less than 50 km away in a straight line.
`}
    <b>Exclusion criteria, to avoid chimerism:</b>
    {`
• Undergoing an organ, tissue, or bone marrow transplant at any time during the participant’s life.
• Receiving a blood transfusion within the last 1 month.
• Ongoing pregnancy.

Data about the participants (incl. health status and medical history) were reported voluntarily by participants, no link with the health registers was made.

`}<b>Ethics: </b>{`
• The project was approved by the ethical committee of each university and/or sample collection site. All participants agreed to process their data and samples, which was documented by signing an informed consent form. 

`}<b>Parameters of library preparation and sequencing:</b>{`
• DNA fragmentation: by Covaris, 350-bp size
• Library preparation kits: KAPA HyperPrep Kit PCR-free or Illumina TruSeq DNA PCR-free
• Sequencing platform: Illumina Novaseq
• Sequencing parameters: Paired-end sequencing, 2×150 bp
• Average coverage: 30x

`}<b>Data analysis:</b>{`
• Nextflow workflow - nf-core/sarek (v2.7.1): More at https://nf-co.re/sarek/2.7.1.
• Sarek was developed with respect to the GATK best practice analysis for germline variants detection.

`}<b>Dataset in version 1.0:</b>{`
The database will be updated and will contain more data in the near future. Currently, the dataset includes these samples: 
• Number of samples in the database: 1016
• Number of female samples: 493
• Number of male samples: 523
• Number of samples from the following regions: 
  • PHA - Prague: 108
  • JHC - South Bohemian Region: 24
  • JHM - Southern Moravia Region: 167
  • KVK - Karlovy Vary Region: 15
  • VYS - Vysocina Region: 55
  • HKK - Hradec Kralove Region: 67
  • LBK - Liberec Region: 26
  • MSK - Moravian-Silesian Region: 109
  • OLK - The Olomouc Region: 87
  • PAK - The Pardubice Region: 38
  • PLK - The Pilsen Region: 53
  • STC - Central Bohemia Region: 117
  • ULK - The Usti Region: 72
  • ZLK - Zlin Region: 76
  • RUZ - Different Regions of Parents: 1
  • NA - Not Available (Was Not Filled in by the Participant): 1

  `}<b>Contact:</b>{`
  • info@acgt.cz

This work was supported from European Regional Development Fund-Project „A-C-G-T“ 
(No. CZ.02.1.01/0.0/0.0/16_026/0008448) which ran from January 2019 to June 2023.
`}
  </div>

  const style = {
    position: 'absolute',
    top: '700px',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    //width: 400,
    maxWidth: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    whiteSpace: 'break-spaces'
  }

  return (
    <AppBar position="static" style={{ backgroundColor: '#7ac143' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <Dataset sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <img src={logo} alt="Logo" style={{ width: '15%', marginRight: '30px' }} />
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {t('title')}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key="search" onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Search</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {t('welcome')}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              key="Search"
              onClick={() => navigate("/")}
              sx={{ my: 2, color: 'white', display: 'block' }}
              disabled={window.location.pathname == '/' ? true : false}
            >
              {t('back')}
            </Button>

            {/*ABOUT BUTTON*/}
            <Button
              key="Search"
              style={{ marginLeft: '20px' }}
              onClick={handleOpenAbout}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {t('about')}
            </Button>

            {/*MODAL START*/}
            <Modal
              open={openAbout}
              onClose={handleCloseAbout}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ overflow: 'overlay' }}
            >
              <Box sx={style}>
                <Row style={{ marginLeft: "auto" }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    ACGT project
                  </Typography>
                  <Button style={{ marginRight: '0px', marginLeft: "auto", color: 'black' }} onClick={handleCloseAbout}>x</Button>
                </Row>
                <br />
                {about}
                <img style={{ width: '100%' }} src={EU} />
                <p>
                ACGT Database tool is a part of services provided by ELIXIR – European research infrastructure for biological information.
                For other services provided by ELIXIR's Czech Republic Node visit <a href="https://elixir-czech.cz/services">elixir-czech.cz/services</a>
                </p>
                <img style={{ width: '25%' }} src={elixirlogo} />
              </Box>
            </Modal>
            {/*MODAL END*/}

          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <LangSwitcher />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {auth.isAuthenticated ?
              <ProfileNavBar user={auth}>
                {avatarComponent}
              </ProfileNavBar>
              :
              <Button variant="outlined" color="inherit" onClick={() => navigate("/login")}>{t('auth.login')}</Button>}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;